<template>
  <div class="page page__content-wrapper">
    <div class="page__min-container page__content-block ">
      <div class="page__text-block">
        <div class="text__normal min-margin">
          Глобальная пандемия 2020 года сильно повлияла на компании во всём мире, дав дополнительный мощный стимул к
          цифровой трансформации и диджитализации самых разных сфер бизнеса.
        </div>
        <div class="text__normal min-margin">
          Эти процессы сразу нашли отражение в трендах современного коммерческого дизайна. На первый план вышли приёмы,
          которые считались не самыми оптимальными для использования в оффлайне и потому реже применялись в брендинге.
        </div>
        <div class="text__normal">
          Сегодня всё больше бизнес-процессов обретают цифровую форму, и ведущие российские и мировые компании — такие
          как Сбер, BMW, Microsoft, X5 Retail Group, Intel — делают выбор в пользу диджитал-дизайна, концентрируясь в
          первую очередь на том, как их бренд будет выглядеть на сайтах, в приложениях, интерфейсах и видеороликах.
        </div>
      </div>

      <div class="style-page__img video-block">
        <img src="~assets/video/Intro.gif" alt="gif">
        <img src="~assets/img/style-page/monitor.png" alt="monitor">
      </div>
      <div class="text__normal margin-mobile">
        GDP идёт в ногу со временем и активно использует суперактуальные тренды в своем брендинге.
      </div>
    </div>
    <div class="page__min-container page__content-block grad-wrapper">
      <div class="text__head">
        Градиент
      </div>
      <div class="text__normal min-margin">
        Фирменный стиль GDP основан на мягких градиентах. Рубиново-розовый луч света освещает синее пространство и
        выступает акцентом.
      </div>
      <div class="text__normal min-margin">
        Мерцающее световое пятно может символизировать точку на географической карте, движущееся пятно — перемещение
        товара, а неподвижное пятно может ассоциироваться с индикатором складской системы pick-to-light.
      </div>
      <div class="text__normal">
        Несмотря на то, что эти метафоры могут послужить хорошими отсылками в ряде случаев, не стоит бросаться в
        крайности и при каждом использовании фирменного градиента вкладывать в него скрытый или сакральный смысл — это
        просто один из инструментов брендинга.
      </div>
      <div class="style-page__gradient">
        <div class="gradient__item">
          <div class="title">
            Midnight Blue
          </div>
          <div class="sub-title">
            <span>
              Hex:
            </span>
            000033
          </div>
        </div>
        <div class="gradient__item">
          <div class="title">
            Sapphire Blue
          </div>
          <div class="sub-title">
            <span>
              Hex:
            </span>
            002266
          </div>
        </div>
        <div class="gradient__item">
          <div class="title">
            Ruby Pink
          </div>
          <div class="sub-title">
            <span>
              Hex:
            </span>
            E5175C
          </div>
        </div>
        <div class="style-page__gradient-cycle">

        </div>
      </div>
    </div>
    <div class="page__min-container page__content-block page__bottom-margin min-margin-img">
      <div class="page__two-block">
        <img src="~assets/img/style-page/grad-1.svg" alt="">
      </div>
      <div class="page__two-block">
        <img src="~assets/img/style-page/grad-2.svg" alt="">
      </div>
      <div class="page__two-block">
        <img src="~assets/img/style-page/grad-3.svg" alt="">
      </div>
      <div class="page__two-block">
        <img src="~assets/img/style-page/grad-4.svg" alt="">
      </div>
    </div>
    <div class="page__min-container page__content-block min-margin-block">
      <div class="text__head">
        Спираль
      </div>
      <div class="text__normal">
        Ключевым элементом айдентики является фирменная спираль. Она строится при помощи градиентов таким образом, чтобы
        производить впечатление синего объекта, освещённого розово-рубиновым светом.
      </div>
      <div class="text__normal">
        Образ спирали отсылает одновременно и к спирали ДНК, и к знаку бесконечности, — символизируя непрерывность
        процессов в фармацевтической компании.
      </div>
      <div class="page__img-wrapper page__min-margin">
        <img src="~assets/img/style-page/spiral.png"
             alt="">
      </div>
    </div>
    <div class="page__min-container page__content-block min-margin-block ">
      <div class="text__head">
        Прямые углы
      </div>
      <div class="text__normal min-margin">
        GDP — это крупная компания, работающая в сегменте B2B. Деловой имидж компании задается строгими прямоугольными
        формами, без скруглений на углах.
      </div>
      <div class="text__normal">
        Коробы для транспортировки товаров, кузова автомобилей, архитектура складских помещений, картонные упаковки
        лекарств — всё это также имеет прямоугольную форму без лишних скруглений. Утилитарность и рационализм этих
        форм<br>запечатлены в айдентике компании.
      </div>
      <div class="page__min-container page__content-block min-margin-img">
        <div class="page__two-block">
          <img src="~assets/img/style-page/angle-1.svg" alt="">
        </div>
        <div class="page__two-block">
          <img src="~assets/img/style-page/angle-2.svg" alt="">
        </div>
        <div class="page__one-block">
          <img src="~assets/img/style-page/angle-3.png" alt="">
        </div>
        <div class="page__two-block">
          <img src="~assets/img/style-page/angle-4.png" alt="">
        </div>
        <div class="page__two-block">
          <img src="~assets/video/ClassA Desktop 800.gif"/>
        </div>
      </div>
    </div>

    <div class="page__min-container page__content-block page__bottom-margin exmple m-zero">
      <div class="text__head">
        Примеры
      </div>
      <div class="text-small">
        Примеры использования фирменного стиля:
      </div>
      <div class="page__min-container page__content-block page__min-margin m-zero">
        <div class="page__one-block">
          <img src="~assets/video/Intro.gif" alt="">
        </div>
        <div class="page__two-block">
          <img src="~assets/img/style-page/exmple-2.png" alt="">
        </div>
        <div class="page__two-block">
          <img src="~assets/img/style-page/exmple-3.png" alt="">
        </div>
        <div class="page__one-block">
          <img src="~assets/img/style-page/exmple-4.png" alt="">
        </div>
        <div class="page__one-block m-zero">
          <img src="~assets/img/style-page/exmple-5.png" alt="">
        </div>
      </div>
    </div>


    <div class="page-nav__tablet">
      <router-link class="next-page__link" to="/identity/picto">
        <img src="~assets/img/icons/row-grey-small.svg" alt="prev">
        Пиктограммы
      </router-link>
      <router-link class="next-page__link" to="/identity/communication-rules">
        Тон коммуникаций
        <img src="~assets/img/icons/row-grey-small.svg" alt="next">
      </router-link>
    </div>
    <div class="next-page">
      <router-link class="next-page__link" to="/identity/photo-style">
        Фотостиль
        <img src="~assets/img/icons/row-grey-small.svg" alt="next">
      </router-link>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      imgSlides: [
        {
          imgSlide: '/img/brand-style-page/slides/Style-11-01.png',
          isChecked: true,
          activeIcon: '/img/brand-style-page/slides/icon-1-active.svg',
          icon: '/img/brand-style-page/slides/icon-1.svg'
        },
        {
          imgSlide: '/img/brand-style-page/slides/Style-11-02.png',
          isChecked: false,
          activeIcon: '/img/brand-style-page/slides/icon-2-active.svg',
          icon: '/img/brand-style-page/slides/icon-2.svg'
        },
        {
          imgSlide: '/img/brand-style-page/slides/Style-11-03.png',
          isChecked: false,
          activeIcon: '/img/brand-style-page/slides/icon-3-active.svg',
          icon: '/img/brand-style-page/slides/icon-3.svg'
        },
        {
          imgSlide: '/img/brand-style-page/slides/Style-11-04.png',
          isChecked: false,
          activeIcon: '/img/brand-style-page/slides/icon-4-active.svg',
          icon: '/img/brand-style-page/slides/icon-4.svg'
        }
      ],
      vid: document.getElementById('vid'),
      isLoad: false
    }
  },
  mounted() {
    setTimeout(() => {
      this.isLoad = true
    }, 30)
    if (this.isLoad) {
      setTimeout(() => {
        this.vid.play()
      }, 30)
    }
  }
}
</script>
<style lang="scss" scoped>
@import "src/assets/style/text-style";
@import "src/assets/style/page-block";

.page {
  margin-bottom: 113px;
}

.video-block {
  position: relative;

  img[alt="gif"] {
    position: absolute;
    left: 26px;
    width: 779px;
    top: 40px;
  }
}

.page__min-container {
  margin-bottom: 48px;

  &:nth-child(2) {
    .text__head {
      line-height: 41px;
      margin-bottom: 9px;
    }
  }

  &:nth-child(4) {
    .text__head {
      line-height: 43px;
      margin-bottom: 7px;
    }
  }

  &:nth-child(5) {
    .text__head {
      line-height: 43px;
      margin-bottom: 7px;
      letter-spacing: 0.9px;
      font-size: 31px;
    }

    &.text__normal {
      margin-bottom: 46px;
    }
  }

  &:nth-child(6) {
    .text__head {
      letter-spacing: 0.5px;
      font-size: 31px;
      line-height: 41px;
      margin-bottom: 8px;
    }
  }

  .page__min-container {
    .page__two-block:nth-child(-n + 2) {
      margin-bottom: 27px;
    }

    .page__one-block {
      margin-bottom: 27.3px;

    }
  }
}

.m-zero {
  .text-small {
    margin-bottom: 49px;
  }
}

.text__normal {
  margin-bottom: 48px;
  max-width: 688px;
}

.text__head {
  font-weight: 500;
}

.style-page__img {
  width: 100%;
  min-width: 1032px;
  margin-bottom: -185px;

  img {
    width: 100%;
  }
}

.style-page__gradient {
  width: 100%;
  min-width: 832px;
  background: linear-gradient(90deg, #000033 0%, #002266 100%);
  height: 416px;
  position: relative;
  overflow: hidden;

  &-cycle {
    position: absolute;
    background: radial-gradient(50% 50% at 50% 50%, #E6175C 0%, rgba(230, 23, 92, 0) 100%);
    height: 200%;
    right: -50%;
    top: 0;
    z-index: 1;
    width: 100%;
  }
}

.gradient__item {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 24px;
  right: 24px;
  color: #fff;

  .title {
    font-size: 24px;
    line-height: 27px;
    font-family: 'Suisseintl', sans-serif;
  }

  .sub-title {
    font-size: 13px;
    line-height: 18px;
    display: flex;
    letter-spacing: 0.02em;

    span {
      opacity: .6;
      margin-right: 4px;
    }
  }

  &:first-child {
    right: auto;
    left: 24px;
    letter-spacing: 0.9px;

    .sub-title {
      margin-top: 9px;
    }
  }

  &:nth-child(2) {
    letter-spacing: 0.6px;

    .sub-title {
      margin-top: 9px;
      margin-left: 77px;
    }
  }

  &:nth-child(3) {
    top: auto;
    bottom: 24px;
    z-index: 2;
    margin-left: 1px;

    .title {
      letter-spacing: 0.16px;

    }

    .sub-title {
      margin-top: 9px;
      margin-left: 31px;
    }
  }
}

.page__one-block {
  width: 100%;
  margin-bottom: 32px;

  img {
    width: 100%;
  }
}

.page__two-block {
  width: calc(50% - 16px);
  margin-bottom: 28px;

  img {
    width: 100%;
  }
}

.grad-wrapper {
  margin-bottom: 32px;
}

.text-small {
  font-size: 16px;
  line-height: 24px;
  color: #606080;
}

.exmple .text__head {
  margin-bottom: 10px;
}

.min-margin {
  margin-bottom: 12px;
}

.min-margin-img {
  margin-bottom: 64px;
}

.min-margin-block {
  margin-bottom: 0;

  .page__img-wrapper {
    margin-bottom: 91px;
  }
}

.text-small {
  margin-bottom: 48px;
}

.page__two-block {
  video {
    width: 100%;
  }
}

@media screen and (max-width: 1456px) {
  .page {
    margin-bottom: 129px;
  }
}

@media screen and (max-width: 900px) {
  .style-page__gradient {
    min-width: 100%;
  }
  .style-page__img {
    min-width: 100%;
    margin-bottom: 0;

    img {
      width: 100%;
    }
  }
  .video-block {
    img[alt="gif"] {
      position: absolute;
      left: 16px;
      width: 459px;
      top: 24px;
    }
  }
}

@media screen and (max-width: 668px) {
  .page {
    .page__min-container {
      .page__two-block {
        width: initial;

        &:nth-child(-n + 2) {
          width: initial;

        }
      }

      .text__normal {
        &.margin-mobile {
          margin-top: -68px;
        }
      }
    }
  }
  .style-page__slider {
    margin: 0 auto 60px;
  }
  .gradient__item .title {
    font-size: 20px;
  }
  img[alt="monitor"] {
    width: 124%;
  }
  .video-block {
    img {
      width: 426px;
    }

    img[alt="gif"] {
      position: absolute;
      left: 12px;
      width: 320px;
      height: 180px;
      top: 17px;
    }
  }
  .style-page__gradient {
    .gradient__item {
      max-width: 100px;
      width: 100%;
      &:nth-child(2) {
        align-items: flex-end;
        .title {
          text-align: end;

        }
      }

      &:nth-child(3) {
        align-items: flex-end;
      }
    }
  }
}
</style>
